import React from 'react';
import SimpleLayout from '../../components/layout_simple';
import Registration from '../../components/registration';
import './styles.scss';

const RegistrationPage = () => {
  return (
    <SimpleLayout isClickable>
      <Registration />
    </SimpleLayout>
  );
};

export default RegistrationPage;
